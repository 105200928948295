<template>
<div>
      <b-card-actions action-collapse title="Liste des abonnés">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.label === 'Status'" class="text-nowrap">
          <span v-if="props.row.status === true">
            <feather-icon icon="CheckIcon" />
          </span>
          <span v-else>
            <feather-icon icon="AlertTriangleIcon" />
          </span>
        </span>
      </template>
           <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SubscriptionsMixin from "@/mixins/subscription.mixin";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";

export default {
  mixins: [SubscriptionsMixin],

  components: {
    BCardActions,
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
  },

  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      columns: [
        {
          label: "From",
          field: 'from.email',
          
        },
        {
          label: "To",
          field: 'to.email',
        },
      ],
      rows: [],
    };
  },

  mounted() {
    this.rows = this.subscriptions;
    console.log("kk ",this.rows)
  },
  methods: {
    // search category
    advanceSearch(val) {
      this.searchTerm = val;
    },
  },
  watch: {
    subscriptions: function () {
      this.rows = this.subscriptions;
          console.log("kk ",this.rows)

    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>